<template>
  <div class="flex h-full">
    <loader :loading="loading" :backdrop="true"/>
    <div v-if="hasSidePanel" class="side-panel flex-shrink-0">
      <!-- @slot Content in the side panel -->
      <slot
        name="side-panel"
        :record="record"
        :loading="loading"
      />
    </div>
    <div class="flex flex-grow content">
      <!-- @slot Main content -->
      <slot
        :record="record"
        :loading="loading"
        :handleSubmit="handleSubmit"
        :redirectToList="redirectToList"
        :getDeletedLabel="getDeletedLabel"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Loader from '@/components/ui/Loader';
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import {isEqual} from "lodash-es";
import {renderTemplate} from "@/utils/String";

export default {
  name: 'Details',
  components: {Loader},
  mixins: [NotifyMixin, ModalNavigation],
  props: {
    /**
     * An entity title that is shown in toast message on updating/creating
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * An API resource name for the specified dataProvider to send a request
     */
    resource: {
      type: String,
      required: true,
    },
    /**
     * A route name to redirect to after saving changes
     */
    basePath: {
      type: String,
      required: true,
    },
    /**
     * A route parameter name that is used as id for the entity to get. It is needed when one entity should be viewed or edited
     */
    routeIdParam: {
      type: String,
      default: 'id'
    },
    /**
     * Request parameters
     */
    requestParams: {
      type: Object,
      default() {
        return {};
      }
    },
    /**
     * A dataProvider name for sending requests
     */
    dataProvider: {
      type: String,
      default: '$authDataProvider'
    },
    /**
     * If you should be redirected back to the list after saving changes
     */
    redirectSaved: {
      type: Boolean,
      default: true,
    },
    /**
     * A field name of the entity to set active modal title
     */
    titleField: {
      type: String
    },
    /**
     * A template string to set active modal title
     */
    titleTemplate: {
      type: String
    },
    /**
     * A renderer function to set active modal title
     */
    titleTemplateRender: {
      type: Function,
    },
    /**
     * A template string to set active modal breadcrumb name
     */
    breadcrumbTemplate: {
      type: String
    },
    /**
     * A renderer function to set active modal breadcrumb name
     */
    breadcrumbTemplateRender: {
      type: Function,
    },
  },
  data: function () {
    return {
      record: {},
      loading: false,
    };
  },
  computed: {
    id: function () {
      return this.$route.params[this.routeIdParam];
    },
    hasSidePanel() {
      return !!this.$slots['side-panel'] || !!this.$slots['side-panel'];
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    requestParams: {
      handler: function (newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      if (this.id) {
        this.loading = true;
        this[this.dataProvider].getOne(this.resource, {id: this.id, ...this.requestParams})
          .then(data => {
            this.record = data;

            if (data[this.titleField]) {
              this.setActiveModalTitle(data[this.titleField]);
            }

            if (this.titleTemplate) {
              this.setActiveModalTitle(renderTemplate(this.titleTemplate, data));
            }

            if (this.titleTemplateRender) {
              this.setActiveModalTitle(this.titleTemplateRender(data));
            }

            if (this.breadcrumbTemplate) {
              this.setActiveModalBreadcrumbName(renderTemplate(this.breadcrumbTemplate, data));
            }

            if (this.breadcrumbTemplateRender) {
              this.setActiveModalBreadcrumbName(this.breadcrumbTemplateRender(data));
            }
          })
          .catch(error => {
            this.redirectToList();
            this.notifyError(error.message);
          })
          .finally(() => this.loading = false);
      }
    },
    handleSubmit(data) {
      if (!this.loading) {
        this.loading = true;
        const action = this.id ? this[this.dataProvider].update : this[this.dataProvider].create;
        const params = {data, ...this.requestParams};
        const toast = `${this.title} has been ${this.id ? 'updated' : 'created'}`;

        if (this.id) {
          params.id = this.id;
        }

        return action(this.resource, params)
          .then(data => {
            this.record = data;

            if (this.redirectSaved) {
              this.redirectToList();
            }

            this.notifySuccess(toast);
          })
          .catch(error => {
            this.notifyError(error.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    redirectToList() {
      this.$router.replace({
        name: this.basePath,
        params: {direction: 'back', ...this.requestParams}
      });
    },
    getDeletedLabel(date) {
      const formattedDate = moment(date).format('LL');
      return `deleted at ${formattedDate}`;
    },
  },
};
</script>

<style scoped>
  .content {
    @apply flex-col overflow-auto;
  }
  .content > div {
    @apply w-full;
  }
</style>

