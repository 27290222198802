<template>
  <!-- <Form :submit="handleSubmit" :initialValues="initialValues"> -->
  <loader :loading="loading" :backdrop="true" />
  <Form :submit="handleSubmit">

      <label class="form-section-title">Calendar Hours</label>

      <hours-of-operations
        v-if="initialHours"
        :website-operation-hours="initHours"
        @operation-hours-change="updateHours"
        :template="{ description: null }"
        resources-available="true"
        no-title
        :disabled="this.loading"
      >
        <template v-slot:operation-days="{ value, update }">
          <dropdown
            placeholder="select a day"
            :options="getOperationDayOptions(value)"
            :value="value"
            @input="update"
          ></dropdown>
        </template>
      </hours-of-operations>
  
      <ModalFooter :footer="footer" :tertiary="cancel" />
   
  </Form>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import Form from "@/components/form/Form";
import HoursOfOperations from "@/components/operation_hours/HoursOfOperations";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Dropdown from "@/components/ui/Dropdown";
import Loader from '@/components/ui/Loader'
import moment from 'moment';
import { groupBy } from 'lodash-es';

const weekDays = ['Monday','Tuesday','Wednesday','Thursday','Friday'];
const weekends = ['Saturday', 'Sunday'];
const allWeekDays = 'Monday - Friday';

export default {
  name: "CalendarSettingsHoursForm",
  components: {
    ModalFooter,
    Form,
    HoursOfOperations,
    Dropdown,
    Loader,
  },
  mixins: [AuthMixin, ValidatorMixin, NotifyMixin],
  emits: ["close"],
  data: function () {
    return {
        loading: false,
        hours: [],
        initialHours: []
    };
  },
  computed: {
    footer() {
      if (this.readonly) {
        return {
          tertiaryButton: "back",
        };
      }
      return {
        primaryButton: "save",
        tertiaryButton: "cancel",
      };
    }, 
    initHours() {
      return JSON.parse(JSON.stringify(this.initialHours));
    }
  },
  created() {
    this.getCalendarHours();
  },
  methods: {
    updateHours(payload) {
      // Updates the hours with the changes 
      this.hours = payload;
      // Emits the updates to the parent in order to update
      // the side panel
      this.$emit('hours-form-updated', this.hours);
    },
    getOperationDayOptions() {
      // CHECK: src/views/auth/customers/communities/CommunityForm.vue
      // Creates an array key => value with all the posible dates
      return [allWeekDays, ...weekDays, ...weekends].map((d) => ({ key: d, value: d }));
    },
    // Get calendar hours from the DB
    getCalendarHours(){
      this.loading = true;
      this.$calendarDataProvider
      .get('calendarGetCommunityResourceTemplateServiceHours', {
        community_id: this.community?.id
      })
      .then((res) => {
        if(!res) {
            return [];
        }
        // res.data.length to res ***
        if(res.length === 0) {
            return [];
        }
        // res.data to res ***
        this.hours = this.initialHours = this.apiToUIFormat(res);
      })
      .catch((err) => this.notifyError(err.message))
      .finally(() => {
        this.loading = false;
      });
    },
    async handleSubmit() {
      this.loading = true;
      if(this.hours.length > 0){
        this.$calendarDataProvider
        .create('calendarSaveCommunityResourceTemplateServiceHours', {
            data: {
              calendar_resources: this.uiToAPIFormat()
            }  
        })
        .then(() => {
            this.notifySuccess('Calendar hours has been updated');
          })
          .catch((err) => this.notifyError(err.message))
          .finally(() => {
            this.loading = false;
          });
      }  
    },
    // convert UI format calendar hours to API request calendar format format
    uiToAPIFormat(){
      let tempArray = [];

      for(let i = 0;i < this.hours.length; i++){

        // Check if day is not closed or has resources available
        if((!this.hours[i].is_Closed) || 
          (this.hours[i].resources_available > 0)){

          // convert hours day of week string into a number of week
          const dayOfWeek = this.hours[i].description;
          const dayOfWeekAsNumbers = this.getDayOfWeekAsNumber(dayOfWeek);

          for(let j = 0;j < dayOfWeekAsNumbers.length; j++){

            const startTime = this.convert12to24HourString(
              this.hours[i].openingTime.hours,
              this.hours[i].openingTime.minutes,
              this.hours[i].openingTime.clock
            );

            const endTime = this.convert12to24HourString(
              this.hours[i].closingTime.hours,
              this.hours[i].closingTime.minutes,
              this.hours[i].closingTime.clock
            );

            tempArray.push(
              {
                community_id: this.community?.id,
                customer_id: this.profile?.customerId,
                day_of_week: dayOfWeekAsNumbers[j],
                start_time: startTime,
                end_time: endTime,
                max_simultaneos_resources: 
                this.hours[i].resources_available,
                day_of_week_group_id: i+1,
              }
            );
          }
        }
      }
      return tempArray.slice();
    },
    // convert API request format calendar hours to UI calendar format
    apiToUIFormat(initHours){

      const groupedDays = groupBy(initHours, "day_of_week_group_id");

      let tempArray = [];

      // Missing loop and remove elements that are part of a week groyp ***
      for (const key in groupedDays) {

        let daysDescription = ""
        const numberOfDays = groupedDays[key].length;

        switch (numberOfDays) {
          case 1:
            daysDescription = this.numberedDayOfWeekAsString(
              groupedDays[key][0].day_of_week
              );
            break;
          case 2:
            daysDescription = "Weekends";
            break;
          case 5:
            daysDescription = "Monday - Friday";
            break;
        }
        tempArray.push({
          description : daysDescription,
          is_closed: false,
          openingTime: this.convert24to12HourFormat(groupedDays[key][0].start_time),
          closingTime: this.convert24to12HourFormat(groupedDays[key][0].end_time),
          open: this.convert24to12HourString(groupedDays[key][0].start_time),
          close: this.convert24to12HourString(groupedDays[key][0].end_time),
          resources_available: groupedDays[key][0].max_simultaneos_resources,
          day_of_week_group_id: groupedDays[key][0].day_of_week_group_id
        });
      }   
      return tempArray.slice();
    },
    cancel(e) {
      e.preventDefault();
      this.$emit("close");
    },
    // Takes day of week description, returns array of days as numbers
    // convert to hashmap ***
    getDayOfWeekAsNumber(dayDescription) {
      let days = [];
      switch (dayDescription) {
        case "Monday":
          days = [1];
          break;
        case "Tuesday":
          days = [2];
          break;
        case "Wednesday":
          days = [3];
          break;
        case "Thursday":
          days = [4];
          break;
        case "Friday":
          days = [5];
          break;
        case "Saturday":
          days = [6];
          break;
        case "Sunday":
          days = [7];
          break;
        case "Monday - Friday":
          days = [1,2,3,4,5];
          break;
        case "Weekends":
          days = [6,7];
          break;
      }
      return days;
    },
    // convert to hashmap ***
    numberedDayOfWeekAsString(dayNumber) {
      let days = "";
      switch (dayNumber) {
        case 1:
          days = "Monday";
          break;
        case 2:
          days = "Tuesday";
          break;
        case 3:
          days = "Wednesday";
          break;
        case 4:
          days = "Thursday";
          break;
        case 5:
          days = "Friday";
          break;
        case 6:
          days = "Saturday";
          break;
        case 7:
          days = "Sunday";
          break;
      }
      return days;
    },

    // convert 12 hour format to 24 hour format with seconds
    convert12to24HourString(hours,minutes,clock){

      // Gets the moment object
      let formattedTime = moment(hours + ":" +  minutes +  " " +  clock, 'hh:mm:ss a').toObject();

      return formattedTime.hours + ":" + formattedTime.minutes + ":" + formattedTime.seconds;

    },

    convert24to12HourString(time){
      const timeObj = this.convert24to12HourFormat(time);
      return timeObj.hours + ":" +  timeObj.minutes +  " " +  timeObj.clock;
    },

    // convert 24 hour format to 12 hour format with seconds
    convert24to12HourFormat(time){ // check time format before spliting ***

      // Gets the moment object
      let parsedTime = moment(time,'hh:mm:ss').toObject();

      var clock = parsedTime.hours > 12 ? 'pm' : 'am';

      // Formats the custom format output
      var output = {
        hours: parsedTime.hours > 12 ? (parsedTime.hours-=12) : parsedTime.hours,
        minutes: parsedTime.minutes == 0 ? '00' : parsedTime.minutes,
        clock: clock
      };

      return output;
    },
  },
};
</script>
