<template>
  <div class="flex flex-col h-full">
    <h3 class="side-panel-heading">
      <!-- @slot Side panel title -->
      <slot name="heading">{{entityTitle}} details</slot>
    </h3>
    <p class="side-panel-text side-panel-scrollable">
      <!-- @slot Content below the title -->
      <slot name="text">{{defaultText}}</slot>
    </p>
  </div>
</template>

<script>
  export default {
    name: "DetailsSidePanel",
    props: {
      /**
       * An entity name that is described inside the sidepanel
       */
      entityTitle: {
        type: String,
        default: '',
      },
      /**
       * If the sidepanel contains a description of the entity that can be viewed only
       */
      viewMode: {
        type: Boolean,
        default: false,
      },
      /**
       * If the sidepanel contains a description of the entity that will be created
       */
      newEntity: {
        type: Boolean,
        default: false,
      },
      /**
       * A name of the external source that is a creator of the entity described inside the sidepanel
       */
      externalSource: {
        type: String,
        default: '',
      },
        /**
         * Scrollable content and fixed header inside the sidepanel
         */
        scrollContent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
      defaultText: function () {
        if (this.newEntity) {
          return `Add the basic information about the ${this.entityTitle}, including required fields.`;
        }

        if (this.viewMode) {
          const externalSource = this.externalSource ? ` pulled from ${this.externalSource}` : '';
          return `View the basic information about the ${this.entityTitle}${externalSource}.`
        }

        return `Edit the basic information about the ${this.entityTitle}, including required fields.`;
      },
    },
  }
</script>
