<template>
  <loader :loading="loading" :backdrop="true" />
  <div class="grid grid-rows-2 grid-flow-col gap-4">
    <div>            
      <p class="mb-1 font-bold text-sm" for="community_name">
      Tour Duration (in minutes)
      </p>
      <dropdown
        :options="durationTimeOptions"
        :placeholder="'select status'"
        :value="parseInt(settings.TOUR_DURATION.value)"
        @input="(val) => update(val, 'TOUR_DURATION')"
      ></dropdown>
    </div>
    <div>
      <p class="mb-1 font-bold text-sm" for="community_name">
        Simultaneous Tour Limit
      </p>
      <dropdown
        :options="tourLimitOptions"
        :value="parseInt(settings.SIMULTANEOUS_TOUR_LIMIT.value)"
        @input="(val) => update(val, 'SIMULTANEOUS_TOUR_LIMIT')"
      ></dropdown>
    </div>
    <div>
      <p class="mb-1 font-bold text-sm" for="community_name">
      Callback Duration (in minutes)
      </p>
      <dropdown
        :options="durationTimeOptions"
        :value="parseInt(settings.CALLBACK_DURATION.value)"
        @input="(val) => update(val, 'CALLBACK_DURATION')"
      ></dropdown>
    </div>
    <div>
      <p class="mb-1 font-bold text-sm" for="community_name">
      Task Duration (in minutes)
      </p>
      <dropdown
        :options="durationTimeOptions"
        :value="parseInt(settings.TASK_DURATION.value)"
        @input="(val) => update(val, 'TASK_DURATION')"
      ></dropdown>
    </div>
  </div>

  <modal-footer :primary="save"></modal-footer>
</template>

<script>

import AuthMixin from '@/components/auth/AuthMixin';
import ValidatorMixin from "@/components/form/ValidatorMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Loader from '@/components/ui/Loader'
import Dropdown from "@/components/ui/Dropdown";

export default {
  name: "CalendarSettingsActivityForm",
  components: {
    ModalFooter,
    Loader,
    Dropdown
  },
  mixins: [AuthMixin, ValidatorMixin, NotifyMixin],
  props: {
    onSubmit: {
      type: Function,
      required: true,
    }
  },
  emits: ["close"],
  data: function () {
    return {
      loading: false,
      firstTimeVisit: true,
      communitySettings: {
        TOUR_DURATION:  {
          value: "15",
          key: 15
        },
        SIMULTANEOUS_TOUR_LIMIT:  {
            value: "1",
            key: 1
          },
        CALLBACK_DURATION: {
            value: "15",
            key: 15
          },
        TASK_DURATION: {
          value: "15",
          key: 15
        },
      },
      settings: {
      TOUR_DURATION:  {
          value: "15",
          key: 15
        },
      SIMULTANEOUS_TOUR_LIMIT:  {
          value: "1",
          key: 1
        },
      CALLBACK_DURATION: {
          value: "15",
          key: 15
        },
      TASK_DURATION: {
          value: "15",
          key: 15
        },
      },
      durationTimeOptions:[
        {
          value: "15",
          key: 15
        },
        {
          value: 30,
          key: 30
        },
        {
          value: 60,
          key: 60
        },                
      ],
      tourLimitOptions:[
        {
          value: 1,
          key: 1
        },
        {
          value: 2,
          key: 2
        },
        {
          value: 3,
          key: 3
        },
         {
          value: 4,
          key: 4
        },
         {
          value: 5,
          key: 5
        },                
      ],
  
    };
  },
  computed: {
    footer() {
      if (this.readonly) {
        return {
          tertiaryButton: "back",
        };
      }
      return {
        primaryButton: "save",
        tertiaryButton: "cancel",
      };
    }
  },
  watch: {
    'settings.CALLBACK_DURATION'(){
      this.communitySettings.CALLBACK_DURATION.setting_value = 
      this.settings.CALLBACK_DURATION?.value;
    },
    'settings.SIMULTANEOUS_TOUR_LIMIT'(){
      this.communitySettings.SIMULTANEOUS_TOUR_LIMIT.setting_value = 
      this.settings.SIMULTANEOUS_TOUR_LIMIT?.value;
    },
    'settings.TASK_DURATION'(){
      this.communitySettings.TASK_DURATION.setting_value = 
      this.settings.TASK_DURATION?.value;
    },
    'settings.TOUR_DURATION'(){
      this.communitySettings.TOUR_DURATION.setting_value = 
      this.settings.TOUR_DURATION?.value;
    }
  },
  created() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    } else {
      this.getCommunitySettings();
    }
  },
  methods: {
    update(value, key) {
      this.settings[key] = {value, key: value};
    },
    // Obtain settings from DB
    getCommunitySettings(){
      this.loading = true;

      this.$calendarDataProvider
      .get('calendarGetCommunitySettings', {
        community_id: this.community?.id
      })
      .then((res) => {
        this.setFormData(res);
      })
      .catch((err) => this.notifyError(err.message))
      .finally(() => {
        this.loading = false;
      });
    },
    // Sets DB setting data to settings object (DB -> UI)
    setFormData(endpointResponse){
      if(endpointResponse.length > 0){
        this.firstTimeVisit = false
        for(var i = 0; i < endpointResponse.length; ++i){
          const setting = endpointResponse[i];
          this.communitySettings[setting.setting_name] = setting;
          this.settings[setting.setting_name].value = setting.setting_value;
          this.settings[setting.setting_name].key = parseInt(setting.setting_value);
        }
      }else{
        this.communitySettings['CALLBACK_DURATION'] = {
            customer_id: this.profile?.customerId,
            community_id: this.community?.id,
            setting_name: "CALLBACK_DURATION",
            setting_value: "15"
        };
        this.communitySettings['SIMULTANEOUS_TOUR_LIMIT'] = {
            customer_id: this.profile?.customerId,
            community_id: this.community?.id,
            setting_name: "SIMULTANEOUS_TOUR_LIMIT",
            setting_value: "1"
        };
        this.communitySettings['TASK_DURATION'] = {
            customer_id: this.profile?.customerId,
            community_id: this.community?.id,
            setting_name: "TASK_DURATION",
            setting_value: "15"
        };
        this.communitySettings['TOUR_DURATION'] = {
            customer_id: this.profile?.customerId,
            community_id: this.community?.id,
            setting_name: "TOUR_DURATION",
            setting_value: "15"
        };
      }
    },
    // Checks if commuity settings are already populated in the DB
    // Otherwise, they will be created (UI -> DB)
    formPayload(){
      let payload = [];
      if(Object.values(this.communitySettings).length === 0) {
        for (const setting in this.settings) {
          payload.push({
            customer_id: this.profile?.customerId,
            community_id: this.community?.id,
            setting_name: setting,
            setting_value: this.communitySettings[setting].value
          });
        }
      }else{
        Object.values(this.settings).forEach(
          (setting, i) => 
            payload.push({
              customer_id: this.profile?.customerId,
              community_id: this.community?.id,
              setting_name: Object.keys(this.settings)[i],
              setting_value: setting.value?.toString()
            }) 
        );
      }
      return payload;
    },
    // Saves community setting data to the DB
    save() {
      const payload = this.formPayload();
      this.loading = true;
      this.$calendarDataProvider
      .create('calendarSaveCommunitySettings', {
          data: {
            settings: payload,
          },
      })
      .then(() => {
        this.notifySuccess('Community Settings have been updated');
      })
      .catch((err) => this.notifyError(err.message))
      .finally(() => {
        this.loading = false;
      });
    },
    cancel(e) {
      e.preventDefault();
      this.$emit("close");
    },
  },
};
</script>
