<template>
  <div class="h-full flex flex-col">
    <div class="form-row mb-6">
      <div class="inline-block mr-5" v-bind:class="{ active: currentTab == 'calendarHours' }">
        <label class="cursor-pointer" @click="selectTab('calendarHours')">Calendar Hours</label>
      </div>
      <div class="inline-block mr-5" v-bind:class="{ active: currentTab == 'activitySettings' }">
        <label class="cursor-pointer" @click="selectTab('activitySettings')">Activity Settings</label>
      </div>
    </div>
    <Details
      title="Calendar Settings"
      base-path="calendar_settings.index"
    >
      <template v-slot:side-panel>
        <DetailsSidePanel>
          <template 
            v-slot:heading
          >
            <div v-if="currentTab === 'calendarHours'">
             Calendar Hours Summary
            </div>
            <div v-if="currentTab === 'activitySettings'"
            >
             Notes
            </div>
          </template>
          <template v-slot:text>
            <div v-if="currentTab === 'calendarHours'">
              <table>
                <tr
                  v-for="(calendarHour, index) in calendarHours"
                  :key="index"
                >
                  <td>
                    <strong>{{ calendarHour.description }}</strong>
                  </td>
                  <td>
                    {{ calendarHour.open }} - {{ calendarHour.close }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-if="currentTab === 'activitySettings'">
              <p>
                Here are a few things you might want to take into consideration when setting activity limits:
              </p>
              <ol>
                <li>
                  How many leasing agents do you typically have available? You probably don’t want to assign 3 simultaneous callbacks if you only have 2 agents.
                </li>
                <li>
                  How many model apartments or showable apartments do you have? Or perhaps, how many golf carts do you have?
                </li>
                <li>
                  Do you mind if more than one group is touring the same apartment at the same time?
                </li>
                <li>
                  Remember, you can manually go in and make adjustments to specific activities if you need to.
                </li>
              </ol>
            </div>
          </template>
        </DetailsSidePanel>    
      </template>
      <template v-slot:default="detailsProps">
        <div v-show="currentTab === 'calendarHours'">
          <CalendarSettingsHoursForm
            :on-submit="detailsProps.handleSubmit"
            :loading="detailsProps.loading"
            @close="detailsProps.redirectToList"
            @hours-form-updated="hoursFormUpdated"
          />
        </div>
        <div v-show="currentTab === 'activitySettings'">
          <CalendarSettingsActivityForm
            :loading="detailsProps.loading"
            @close="detailsProps.redirectToList"
          />          
        </div>
      </template>
    </Details>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import CalendarSettingsHoursForm from "@/components/calendar/settings/HoursForm";
import CalendarSettingsActivityForm from "@/components/calendar/settings/ActivityForm";
import Details from "@/components/auth/details/Details";
import ModalNavigation from "@/mixins/ModalNavigation";
import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: {
    CalendarSettingsHoursForm,
    CalendarSettingsActivityForm,
    DetailsSidePanel,
    Details,
  },
  data() {
    return {
      loading: false,
      currentTab: "calendarHours",
      errorTabs: {},
      calendarHours: null
    };
  },
  methods: {
    selectTab(selectedTab) {
      this.currentTab = selectedTab;
    },

    hoursFormUpdated(payload) {
      this.calendarHours = payload;
    },      
  },
  created() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    }
  },
};
</script>

<style scoped>
.active {
  @apply text-active-500 border-active-500 border-b-2;
}

ol {
    padding: 20px 0; 
    list-style-type: decimal;
}
ol li {
    margin: 0 0 10px 20px;
    padding-left: 10px;
}
</style>
